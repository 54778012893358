
import { defineComponent } from 'vue';
import TopSection from './components/TopSection.vue';
import ExperienceModal from './components/ExperienceModal.vue';
import ProjectsModal from './components/ProjectsModal.vue';
import EducationModal from './components/EducationModal.vue';
import BoxSection from './components/BoxSection.vue';
import Loader from './components/Loader.vue';
import { experienceModalOpen } from './utils/experienceInfo';
import { projectsModalOpen } from './utils/projectsInfo';
import { educationModalOpen } from './utils/educationInfo';
import { experienceInfo } from './utils/experienceInfo';
import { projectsInfo } from './utils/projectsInfo';
import { skillsInfo } from './utils/skillsInfo';
import { educationInfo } from './utils/educationInfo';
import { imageSources } from './utils/imageSources';

export default defineComponent({
  components: {
    TopSection,
    ExperienceModal,
    ProjectsModal,
    EducationModal,
    BoxSection,
    Loader
  },
  data(){
    return {
      experienceModalOpen: experienceModalOpen,
      educationModalOpen: educationModalOpen,
      projectsModalOpen: projectsModalOpen,
      experienceInfo: experienceInfo,
      projectsInfo: projectsInfo,
      skillsInfo: skillsInfo,
      educationInfo: educationInfo,
      imageSources: imageSources,
      imagesLoaded: false
    }
  },
  methods: {
    preloadImages() {
      this.imageSources.forEach((imageSrc: string) => {
        const img = new Image();
        img.src = imageSrc;
        img.onload = this.onImageLoad;
        this.imagesLoaded = true;
      });
    },
    onImageLoad() {
      console.log('Image loaded');
    },
  },
  mounted() {
    this.preloadImages();
  },
});
