
import { defineComponent } from 'vue';
import ContentBox from './/ContentBox.vue';
import SkillBox from './SkillBox.vue';

export default defineComponent({
    props: ['title', 'allInfo'],
    components: {
        ContentBox, SkillBox
    },
    data() {
        return {
            screen: "computer",
            showMore: false,
        }
    },
    methods: {
        resize() {
            window.innerWidth > 700 ? this.screen = "computer" : this.screen = "phone";
        },
        setShowMore(state: boolean) {
            this.showMore = state;
        }
    },
    mounted() {
        this.resize();
        window.addEventListener('resize', this.resize);
    },
});
