
import { defineComponent } from 'vue';
import { experienceModalOpen, experienceModalInfo } from '../utils/experienceInfo'
import { projectsModalInfo, projectsModalOpen } from '@/utils/projectsInfo';
import { educationModalInfo, educationModalOpen } from '@/utils/educationInfo';
import { logEducationBoxClick, logExperienceBoxClick, logProjectBoxClick } from '@/utils/analytics';

export default defineComponent({
    props: ['info'],
    data() {
        return {
            fit: "h-full",
            titleSize: "font-size: 1.67vw",
            screen: "computer",
            boxWidth: "25.85vw",
        }
    },
    methods: {
        boxClicked() {
            if (this.info.company) {
                experienceModalOpen.value = true;
                experienceModalInfo.value = this.info;
                logExperienceBoxClick(this.info.title, this.info.company);
            } else if (this.info.school) {
                educationModalOpen.value = true;
                educationModalInfo.value = this.info;
                logEducationBoxClick(this.info.school);
            } else {
                projectsModalOpen.value = true;
                projectsModalInfo.value = this.info;
                logProjectBoxClick(this.info.title);
            }
        },
        resize() {
            window.innerWidth > 700 ? this.screen = "computer" : this.screen = "phone";

            this.fit = "w-full";

            if (this.screen == 'computer') {
                if (this.info.title == "Projex" || this.info.title == "NewsGuesser" || this.info.title == "CivicSendNE"
                    || this.info.title == "Portfolio" || this.info.school == "Elkhorn South High School") {
                    this.fit = "h-full";
                }
                if (!this.info.company) {
                    this.titleSize = "font-size: 1.875vw";
                } else {
                    this.titleSize = "font-size: 1.67vw";
                }
            } else {
                if (this.info.title == "Projex" || this.info.title == "NewsGuesser" || this.info.title == "CivicSendNE"
                    || this.info.title == "Portfolio" || this.info.school == "Elkhorn South High School") {
                    this.fit = "h-full";
                }
                if (!this.info.company && !this.info.school) {
                    this.titleSize = "font-size: 6vw";
                } else if (this.info.school) {
                    this.titleSize = "font-size: 4.5vw";
                } else {
                    this.titleSize = "font-size: 5vw";
                }
            }

            if (this.info.school && this.screen == 'computer') {
                if (this.info.school == "University of Nebraska-Lincoln") {
                    this.boxWidth = "48.6vw";
                } else {
                    this.fit = "h-full";
                    this.boxWidth = "30vw";
                }
            }
        }
    },
    mounted() {
        this.resize();
        window.addEventListener('resize', this.resize);
    },
});
