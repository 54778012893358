
import { defineComponent } from 'vue';
import { logContactIconClick } from '../utils/analytics';

export default defineComponent({
    data() {
        return {
            screen: "computer"
        }
    },
    methods: {
        resize() {
            window.innerWidth > 700 ? this.screen = "computer" : this.screen = "phone";
        },
        logContactIconClick(icon: string) {
            logContactIconClick(icon);
        },
    },
    mounted() {
        this.resize();
        window.addEventListener('resize', this.resize);
    }
});
