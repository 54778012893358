
import { defineComponent } from 'vue';
import ContactIcons from './/ContactIcons.vue';

export default defineComponent({
    components: {
        ContactIcons
    },
    data(){
        return {
            screen: "computer"
        }
    },
    methods: {
        resize(){
            window.innerWidth > 700 ? this.screen = "computer" : this.screen = "phone";
        }
    },
    mounted(){
        this.resize();
        window.addEventListener('resize', this.resize);
    }
});
