import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["box flex justify-center items-center font-semibold text-black text-center", _ctx.type])
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.screen == 'phone' ? 'pr-1 pl-1' : 'pr-4 pl-4')
    }, _toDisplayString(_ctx.info), 3)
  ], 2))
}