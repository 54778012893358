
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['info', 'type'],
    data() {
        return {
            screen: "computer",
        }
    },
    methods: {
        resize(){
            window.innerWidth > 700 ? this.screen = "computer" : this.screen = "phone";
        }
    },
    mounted(){
        this.resize();
        window.addEventListener('resize', this.resize);
    },
});
