import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-screen h-screen" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EducationModal = _resolveComponent("EducationModal")!
  const _component_ExperienceModal = _resolveComponent("ExperienceModal")!
  const _component_ProjectsModal = _resolveComponent("ProjectsModal")!
  const _component_TopSection = _resolveComponent("TopSection")!
  const _component_BoxSection = _resolveComponent("BoxSection")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageSources, (imageSrc) => {
      return (_openBlock(), _createElementBlock("img", {
        style: {"display":"none"},
        onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImageLoad && _ctx.onImageLoad(...args))),
        key: imageSrc,
        src: imageSrc
      }, null, 40, _hoisted_2))
    }), 128)),
    (_ctx.imagesLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.educationModalOpen)
            ? (_openBlock(), _createBlock(_component_EducationModal, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.experienceModalOpen)
            ? (_openBlock(), _createBlock(_component_ExperienceModal, { key: 1 }))
            : _createCommentVNode("", true),
          (_ctx.projectsModalOpen)
            ? (_openBlock(), _createBlock(_component_ProjectsModal, { key: 2 }))
            : _createCommentVNode("", true),
          _createVNode(_component_TopSection),
          _createVNode(_component_BoxSection, {
            title: "EXPERIENCE",
            allInfo: _ctx.experienceInfo
          }, null, 8, ["allInfo"]),
          _createVNode(_component_BoxSection, {
            title: "PROJECTS",
            allInfo: _ctx.projectsInfo
          }, null, 8, ["allInfo"]),
          _createVNode(_component_BoxSection, {
            title: "SKILLS",
            allInfo: _ctx.skillsInfo
          }, null, 8, ["allInfo"]),
          _createVNode(_component_BoxSection, {
            title: "EDUCATION",
            allInfo: _ctx.educationInfo
          }, null, 8, ["allInfo"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Loader)
        ]))
  ]))
}