
import { educationModalInfo, educationModalOpen } from '@/utils/educationInfo';
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
            screen: "computer",
            info: educationModalInfo
        }
    },
    methods: {
        handleClose() {
            educationModalOpen.value = false;
        },
        resize() {
            window.innerWidth > 700 ? this.screen = "computer" : this.screen = "phone";
        }
    },
    mounted() {
        this.resize();
        window.addEventListener('resize', this.resize);
    },
});
